import {ApiService} from './services/api.service';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule,CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA} from '@angular/core';
import {AgmCoreModule} from '@agm/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {InterceptorService} from './services/interceptor.service';
import {PdfViewerModule} from 'ng2-pdf-viewer';


//imports
import {ToastrModule} from 'ngx-toastr';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {TooltipModule} from 'ngx-tooltip';


import {ExternalAuthguardService} from './services/externalguard.service';
import {AuthGuardService} from './services/authguard.service';
import {NotFoundComponent} from './modules/common/not-found/not-found.component';
import {LightboxModule} from 'ngx-lightbox';
//import {GoogleplaceDirective} from './modules/shared/directives/googlePlaces.directive';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {environment} from './../environments/environment';
import {MessagingService} from './services/messaging.service';
import {SocketService} from './services/socket.service';
import { ServiceWorkerModule } from '@angular/service-worker';
//import { TrackLiveTrackComponent } from './modules/common/track-live-track/track-live-track.component';


// import {NumberOnlyDirective} from './modules/shared/directives/numOnly.directive';
@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    CommonModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressAnimation: 'increasing',
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      progressBar: true
    }),
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    Ng2TelInputModule,
    LightboxModule,
    TooltipModule,
    PdfViewerModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
   
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAbH5dlLsPapMreDphjjYtTbdj0_xHdLt8',
                  // apiKey: 'AIzaSyDuGjsHa9ZXtwTGSQvP76c-DG2A-18VXJw',
      libraries: ['drawing', 'geometry','places','directions'],
    }),
   
  ],
  providers: [
    ApiService,
    SocketService,
    ExternalAuthguardService,
    AuthGuardService,
    MessagingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
  ],
  schemas: [],
  exports: [
  ],
  bootstrap: [AppComponent],
  
})
export class AppModule {
}

// positionClass: 'toast-top-center',
