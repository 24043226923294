import { Injectable } from "@angular/core";
import * as mixpanel from "mixpanel-browser";
import { environment } from "../../environments/environment";
import { SessionService } from "./session.service";

@Injectable({
  providedIn: "root",
})
export class MixpanelService {
  mix_panel_key: String;
  obj: any;

  constructor(private sessionService: SessionService) {
    this.mix_panel_key = environment.MIX_PANEL_KEY;
  }
  /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   * @memberof MixpanelService
   */
  init(): void {
    console.log("initializing mix panel tracking");
    mixpanel.init(this.mix_panel_key);
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    this.obj = this.sessionService.get("user_data");
    if (id != "forgot password") {
      this.setPeople({
        $email: this.obj["email"],
        $name: this.obj["name"],
        $phone: this.obj["phoneNo"],
        $distinct_id: this.obj["email"],
      });
    }

    mixpanel.track(id, action);
  }

  alias(id: string): void {
    mixpanel.alias(id);
  }

  identify(id: string): void {
    mixpanel.identify(id);
  }

  setPeople(data: any = {}): void {
    console.log(data);
    mixpanel.people.set(data);
  }
}
