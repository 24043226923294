import {Injectable} from '@angular/core';
import {Router, CanActivate, CanDeactivate, ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {SessionService} from './session.service';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class ExternalAuthguardService implements CanActivate, CanDeactivate<CanComponentDeactivate> {
  constructor(
    public router: Router,
    private sessionService: SessionService
  ) {
  }

  canActivate(): boolean {
    if (!this.isAuthenticated()) {
      return true;
    }
    const user_data = this.sessionService.get('user_data');
   // console.log(user_data, 'external-auth-guard');
    // if (user_data.mobileVerification) {
      this.router.navigate(['/dashboard/home']);
    // }
    // else {
    //
    //   this.router.navigate(['/auth/verify-otp']);
    // }
    return false;
  }

  canDeactivate(component: CanComponentDeactivate) {
    return component.canDeactivate ? component.canDeactivate() : true;
  }

  isAuthenticated(): boolean {
    const token = localStorage.getItem('access_token');
    return !!token;
  }
}
