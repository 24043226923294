import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {apiConstants} from '../constants/api.constants';
import {of, Observable} from 'rxjs';
import loader from '@angular-devkit/build-angular/src/angular-cli-files/plugins/single-test-transform';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiEndpoint: any;
  apiConstants: any;
  private domainName = 'ria-food.yelo.red' || window.location.hostname;
  private countryPromise: Promise<any>;

  constructor(private http: HttpClient) {
    this.apiConstants = apiConstants;
    this.apiEndpoint = environment.API_ENDPOINT;

  }

  getData(url, obj, load) {
    let params = new HttpParams();
    Object.keys(obj).forEach(key => {
      if (obj[key] !== '' && obj[key] !== undefined) {
        params = params.set(key, obj[key]);
      }
    });
    return this.http.get<any>(this.apiEndpoint + url, {params, reportProgress: load});
  }

  postData(url, obj, load) {
    const formData = new FormData();
    Object.keys(obj).forEach(key => {
      if (obj[key] !== '' && obj[key] !== undefined) {
      //  console.log(obj[key])
        formData.append(key, obj[key]);
      }
    });
    //console.log(formData)
    return this.http.post<any>(this.apiEndpoint + url, formData, { reportProgress: load});
  }


  post(url, data) {
    url = environment.API_ENDPOINT + url;
    let headers;
    if (localStorage.getItem('access_token')) {
      headers = {
        authorization: localStorage.getItem('access_token')
      };
    }
    // data.body['marketplace_reference_id'] = this.config.marketplaceReferenceId;
    // if (!('language' in data.body)) {

    // }
    return this.http.post(url, data, {headers});
  }

  private mapDataToUrl(data: any) {
    if (data.body) {
      const obj = {
        ...data.body
      };

      const params = [];
      Object.keys(obj).forEach(key => {
        params.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
        );
      });
      return params.join('&');
    } else {
      return '';
    }
  }

  public get(data) {
    let url = '';

    url = environment.API_ENDPOINT + data.url;

    const params = this.mapDataToUrl(data);
    url += '?' + params;
    return this.http
      .get(url, {params: data.params})
      .pipe(map(this.tookanResponse));
  }


  tookanResponse(res: any) {
    return res;
  }

  uploadImage(url, file) {
    console.log(url,file)
    const formData = new FormData();
    formData.append('image', file);
    return this.http.post<any>(this.apiEndpoint + url, formData, {reportProgress: true});
  }

  uploadImagewithProgressBar(url, file) {
    const formData = new FormData();
    formData.append('image', file);
    return this.http.post<any>(this.apiEndpoint + url, formData, {reportProgress: true, observe: "events"});
  }

}
