// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API_ENDPOINT: 'http://18.144.25.247:7000', //dev
  // API_ENDPOINT: 'http://13.56.106.222:7000',  //test
  //   API_ENDPOINT: 'http://52.8.197.168:7000',//live
  //  API_ENDPOINT: 'https://api.trukkin.com',  // live
  //API_ENDPOINT: 'http://13.56.106.222:7000',   //Dev
  API_ENDPOINT: "https://test-api.trukkin.com", //test
  TRACKING_LINK: "https://test-live-mapping-v2.trukkin.com/#/track/",
  firebaseConfig: {
    apiKey: "AIzaSyAbH5dlLsPapMreDphjjYtTbdj0_xHdLt8",
    authDomain: "trukkindev.firebaseapp.com",
    databaseURL: "https://trukkindev.firebaseio.com",
    projectId: "trukkindev",
    storageBucket: "trukkindev.appspot.com",
    messagingSenderId: "37074936559",
    appId: "1:37074936559:web:17d5710b970c9c07f4711a",
    measurementId: "G-N0J1XKKWNR",
  },
  MIX_PANEL_KEY: "80e7a7fb477615360bc8d4b0e29751fc",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
