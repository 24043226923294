import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Subscription } from "rxjs";
import { SessionService } from "./services/session.service";
import { SwUpdate } from "@angular/service-worker";
import { MessagingService } from "./services/messaging.service";
import { MixpanelService } from "./services/mixpanel.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  title = "trucckin";
  param = { value: "world" };
  loading: any;
  private subscription: Subscription;

  constructor(
    private sessionService: SessionService,
    private mixpanelService: MixpanelService,
    private swUpdate: SwUpdate
  ) {
    this.subscription = this.sessionService.loaderStatus.subscribe((status) => {
      this.loading = status;
    });
  }

  ngOnInit() {
    this.mixpanelService.init();
    window.scrollTo(0, 0);

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm("New version available. Load New Version?")) {
          window.location.reload();
        }
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
