import {Injectable} from '@angular/core';
import {Router, CanActivate, CanDeactivate, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {SessionService} from './session.service';
import {ToastrService} from 'ngx-toastr';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class AuthGuardService implements CanActivate, CanDeactivate<CanComponentDeactivate> {
  constructor(
    public router: Router,
    private sessionService: SessionService,
    private toastr: ToastrService,
  ) {
  }

  canActivate( route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot ): boolean {
    if (!this.isAuthenticated()) {
      this.router.navigate(['/auth/login']);
      if(route.url[0].path == 'truck-tracking') {
        this.toastr.error('Session expired. Please Login again.');
      }
      return false;
    }
    const user_data = this.sessionService.get('user_data');
     //console.log(user_data,'auth-guard')
    // if (!user_data.mobileVerification) {
    //   this.router.navigate(['/dashboard/home']);
    // } else {
    //   this.router.navigate(['/dashboard/verify-otp']);
    // }
    return true;
  }

  canDeactivate(component: CanComponentDeactivate,
                route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot) {

    let url: string = state.url;
    console.log('Url: '+ url);

    return component.canDeactivate ? component.canDeactivate() : true;
  }

  isAuthenticated(): boolean {
    const token = localStorage.getItem('access_token');
    return !!token;
  }
}
